var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-tabs',{staticClass:"tabs__cashier mt-0",staticStyle:{"position":"relative"}},[_c('b-tab',{attrs:{"id":"test-tour","active":"","title":"Terakhir Dipilih"},on:{"click":function($event){(_vm.filters.only_favorite = 0), (_vm.filters.sort_by = 'selected_count')}}},[(_vm.isLoading)?_c('div',{staticClass:"text-center"},[_c('b-spinner',{attrs:{"variant":"primary"}})],1):_c('div',_vm._l((_vm.result.data),function(item,index){return _c('b-card',{key:index,staticClass:"mb-1 card__product",staticStyle:{"border-radius":"1rem"},attrs:{"no-body":""}},[_c('CardProduct',{attrs:{"firstIndex":index,"id-edit":_vm.id,"is-loading":_vm.isLoadingEdit,"get-data":_vm.getData,"detail-product":_vm.detailProduct,"products":item,"is_favorite":item.is_favorite}})],1)}),1),(_vm.result.data && _vm.result.data.length == 0 && !_vm.isLoading)?_c('div',[_c('h6',[_vm._v("Favorite produk tidak ditemukan")])]):_vm._e()]),_c('b-tab',{attrs:{"title":"Favorit"},on:{"click":function($event){_vm.filters.only_favorite = 1}}},[(_vm.isLoading)?_c('div',{staticClass:"text-center"},[_c('b-spinner',{attrs:{"variant":"primary"}})],1):_c('div',_vm._l((_vm.result.data),function(item,index){return _c('b-card',{key:index,staticClass:"mb-1 card__product",staticStyle:{"border-radius":"1rem"},attrs:{"no-body":""}},[_c('CardProduct',{attrs:{"id-edit":_vm.id,"is-loading":_vm.isLoadingEdit,"get-data":_vm.getData,"detail-product":_vm.detailProduct,"products":item,"is_favorite":item.is_favorite}})],1)}),1),(_vm.result.data && _vm.result.data.length == 0 && !_vm.isLoading)?_c('div',[_c('h6',[_vm._v("Favorite produk tidak ditemukan")])]):_vm._e()]),_c('div',{staticClass:"filter__container",class:{ 'd-none': _vm.filters.only_favorite == 0 }},[_c('b-button',{staticClass:"bg-transparent",class:{
        'opacity-1': _vm.filters.only_favorite == 1,
        active: _vm.filter == 'populer',
      },attrs:{"id":"button--popular"},on:{"click":function($event){(_vm.filters.sort_by = 'selected_count'),
          (_vm.filter = 'populer'),
          (_vm.filters.sort_type = '')}}},[_vm._v(" Populer ")]),_c('b-button',{staticClass:"bg-transparent",class:{
        'opacity-1': _vm.filters.only_favorite == 1,
        active: _vm.filter == 'a-z',
      },attrs:{"id":"button--a__z"},on:{"click":function($event){(_vm.filters.sort_by = ''),
          _vm.filters.sort_type == 'asc'
            ? (_vm.filters.sort_type = 'desc')
            : (_vm.filters.sort_type = 'asc'),
          (_vm.filter = 'a-z')}}},[_vm._v(" A-Z ")])],1)],1),_c('ModalEditProduct',{key:2,attrs:{"modal-key":'product',"result":_vm.resultDetail,"form-data":_vm.formPayload,"create-item-to-cart":_vm.createItemToCart,"messages":_vm.messages,"loading":_vm.isLoading,"stock-warehouse":_vm.stock,"type-warehouses":_vm.typeWarehouse},on:{"typeWarehouse":_vm.getTypeWarehouse,"payload":_vm.getPayload}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }